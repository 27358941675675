import React, { useMemo } from 'react';
import { below } from 'src/components/global/mediaqueries';
import Breadcrumbs from 'src/components/Breadcrumbs';
import ErrorCatcher from 'src/components/ErrorCatcher';
import Modules from 'src/load-module';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import theme from 'src/styles/theme';

const getPermalinkPaths = permalink => {
    const splitPermalink = permalink.split('/');
    return splitPermalink.filter(path => path !== '');
};

const isSubPageOf = (permalink, subPath) => {
    const permalinkPaths = getPermalinkPaths(permalink);

    if (permalinkPaths.length > 1) {
        return !!permalinkPaths.find(path => path === subPath);
    }

    return false;
};

//This will replace '-' with spaces, and capitalize the first letter
const formatCrumbName = path => (path.charAt(0).toUpperCase() + path.slice(1)).replace('-', ' ');

const getPreviousPagesFromPermalink = permalink => {
    const permalinkPaths = getPermalinkPaths(permalink);
    permalinkPaths.pop();

    return(
        permalinkPaths.length > 0 ?
            permalinkPaths.map((path) => {
                return {
                    name: formatCrumbName(path),
                    link: `/${path}/`,
                };
            })
        :
            [{
                name: 'Home',
                link: '/',
            }]
    );
};

const BreadcrumbsWrapper = styled('div')`
    z-index: 100;
    position: absolute;
    max-width: 100%;

    ${theme.spacing.mobile._16('margin-top')};
    ${theme.spacing.mobile._12('padding-left')};
    ${theme.spacing.mobile._24('padding-right')};

    ${theme.spacing.tablet._24('margin-top')};
    ${theme.spacing.tablet._20('padding-left')};
    ${theme.spacing.tablet._40('padding-right')};

    ${theme.spacing.desktop._32('margin-top')};
    ${theme.spacing.desktop._32('padding-left')};
    ${theme.spacing.desktop._64('padding-right')};

    ${below.desktop_s} {
        &.on-handheld-position-relative {
            position: relative;
        }
    }
`;

const PageTemplate = ({ page } ) => {
    const hasNoHeroAndStartsWithTextModule = page.acfData.heroContent.length === 0 && page.acfData.pageContent[0].module === 'text_module';
    const isFaqPage = page.permalink.toLowerCase().indexOf('faq') !== -1;

    const renderHeroContent = useMemo(() => {
        return page.acfData.heroContent.map((data, index) =>
            <ErrorCatcher key={index}>
                {
                    React.createElement(Modules(data.module), {
                        module: data.module,
                        title: '',
                        data: data.data,
                    })
                }
            </ErrorCatcher>
        );
    }, [page]);

    const renderPageContent = useMemo(() => {
        return (
            page.acfData.pageContent.map((data, index) =>
                <ErrorCatcher key={index}>
                    {      
                        React.createElement(Modules(data.module), {
                            key: index,
                            module: data.module,
                            title: '',
                            data: data.data,
                        })
                    }
                </ErrorCatcher>
            )
        );
    }, [page]);

    return(
        <>
            { ( isSubPageOf(page.permalink, 'about-us') || isFaqPage ) &&
                <BreadcrumbsWrapper className={hasNoHeroAndStartsWithTextModule ? 'on-handheld-position-relative' : null}>
                    <Breadcrumbs pageTitle={page.postTitle} previousPages={getPreviousPagesFromPermalink(page.permalink)}/>
                </BreadcrumbsWrapper>
            }
            {renderHeroContent}
            {renderPageContent}
        </>
    );
};

PageTemplate.propTypes = {
    page: PropTypes.shape({
        acfData: PropTypes.shape({
            heroContent: PropTypes.arrayOf(
                PropTypes.shape({
                    module: PropTypes.string,
                    title: PropTypes.string,
                    data: PropTypes.object,
                })
            ),
            pageContent: PropTypes.arrayOf(
                PropTypes.shape({
                    module: PropTypes.string,
                    title: PropTypes.string,
                    data: PropTypes.object,
                })
            ),
        }),
        permalink: PropTypes.string,
        postTitle: PropTypes.string,
    }).isRequired,
  };

export default PageTemplate;
